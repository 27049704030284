import React from 'react';
import type { HeadFC } from 'gatsby';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import Page5 from '../components/InteractivePart/Page5/index';
import Feedback from '../components/InteractivePart/Page2/Feedback/index';
import HomeEquity from '../components/InteractivePart/Page5/HomeEquity/index'

const sizeClass = 'mx-auto';

function page5() {
  return (
    <main>
      <Header className={sizeClass} />

      <div className={`container py-6 lg:py-16 ${sizeClass}`}>
        <section className="flex flex-col xl:flex-row-reverse gap-8 lg:gap-16">
          <div className="flex-1">
            <Page5 />
          </div>
        </section>
      </div>
      <div className="chart bg-white">
        <section className={`container ${sizeClass}`}>
        {/* <HomeEquity /> */}
          <Feedback />
        </section>
      </div>

      <Footer className={sizeClass} />
    </main>
  );
}

export default page5;

export const Head: HeadFC = () => <title>Nuborrow.com | Home Equity Made Easy</title>;
